import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from "styled-components"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  flex-flow: row;
  background-color: rgba(30, 139, 195, 1);
`

const HeaderText = styled.div`
  border: 0px solid green;
  font-family: montserrat;
  width: 100%;
`
const List = styled.ul`
  margin: 0;
  padding: 1rem;
  color: white;
  background-color: (30, 139, 195, 1);
`

const BreadCrumb = styled.li`
  display: inline;
  padding: 1rem;
`
const BreadCrumbLink = styled(Link)`
  color: white;
  text-align: center;
  padding: 24px 16px;
  color: #ccc;
  text-decoration: none;
`
const PanelRow = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column;
    padding: 1rem;
  }
  @media (min-width: 768px) {
    flex-flow: row;
    padding: 5rem;
  }
`

const ContactContainer = styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  width: 100%;
  color: black;
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
`

const IndexPage = () => (
  <Layout>
    <HeaderSection>
      <HeaderText>
        <List>
          <BreadCrumb>
            <span>
              <BreadCrumbLink to="/">Homes </BreadCrumbLink>
            </span>

            <span>Contact us</span>
          </BreadCrumb>
        </List>
      </HeaderText>
    </HeaderSection>
    <div
      style={{
        color: "black",
        paddingLeft: "4rem",
        paddingRight: "4rem",
        textAlign: "center",
      }}
    >
      <h1 style={{ fontWeight: "30", fontFamily: "Montserrat" }}>
        Get In Touch
      </h1>
      <p>
        We’re very approachable! Please feel free to call, send us an email or
        just fill in the simple form below. We’d love to hear from you.
      </p>
    </div>

    <PanelRow>
      <div
        style={{
          backgroundColor: "black",
          padding: "2rem",
          flexGrow: 1,
          height: "10rem",
          borderRadius: "3%",
          margin: "1rem",
          fontFamily: "Montserrat",
        }}
      >
      </div>
      <div
        style={{
          backgroundColor: "black",
          padding: "2rem",
          flexGrow: 1,
          height: "10rem",
          borderRadius: "3%",
          margin: "1rem",
          fontFamily: "Montserrat",
        }}
      >
        <h2> Phone details</h2>
      </div>
      <div
        style={{
          backgroundColor: "black",
          padding: "2rem",
          flexGrow: 1,
          height: "10rem",
          borderRadius: "3%",
          margin: "1rem",
          fontFamily: "Montserrat",
        }}
      >
        <h2> Working Hours</h2>
      </div>
    </PanelRow>
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        paddingLeft: "5rem",
        paddingRight: "5rem",
        paddingBottom: "5rem",
      }}
    >
      <ContactContainer>
        <form >
          <label for="fname">First Name</label>
          <input
            type="text"
            id="fname"
            name="firstname"
            placeholder="Your name.."
          />

          <label for="lname">Last Name</label>
          <input
            type="text"
            id="lname"
            name="lastname"
            placeholder="Your last name.."
          />

          <label for="country">Country</label>
          <select id="country" name="country">
            <option value="australia">Australia</option>
            <option value="canada">Canada</option>
            <option value="usa">USA</option>
          </select>

          <label for="subject">Subject</label>
          <textarea
            id="subject"
            name="subject"
            placeholder="Write something.."
            style={{ height: "200px" }}
          ></textarea>

          <input type="submit" value="Submit" />
        </form>
      </ContactContainer>
    </div>
  </Layout>
)

export default IndexPage
